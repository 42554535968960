export const BREAKPOINT_SIZES = {
  screenExtraSmall: {
    size: 0,
    code: 'xs',
  },
  screenSmall: {
    size: 580,
    code: 'sm',
  },
  screenMedium: {
    size: 992,
    code: 'md',
  },
  screenLarge: {
    size: 1400,
    code: 'lg',
  },
  screenExtraLarge: {
    size: 1800,
    code: 'xl',
  },
}

export const mqMin = Object.keys(BREAKPOINT_SIZES).reduce((acc, key) => {
  const currentBreakpoint = BREAKPOINT_SIZES[key]
  acc[currentBreakpoint.code] = `@media screen and (min-width: ${currentBreakpoint.size}px)`
  return acc
}, {})

export const mqMax = Object.keys(BREAKPOINT_SIZES).reduce((acc, key) => {
  const currentBreakpoint = BREAKPOINT_SIZES[key]
  acc[currentBreakpoint.code] = `@media screen and (max-width: ${currentBreakpoint.size}px)`
  return acc
}, {})
